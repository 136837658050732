import React from "react";
import {Heading,Box, VisuallyHidden} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import {motion} from 'framer-motion'
import MeinSeo from '../components/SEO'

export default function Spielplan() {
    return(
        <motion.div
        initial={{
            opacity:0
        }}
        animate={{opacity:1 }}
        transition={{duration:1}}
        >
           <MeinSeo title="Spielplan Theaterfestival 2022" description="Theaterfestival der Poesie im Oberen Mittelrheintal" />
        <Box py="10">
            <VisuallyHidden>
       <Heading pl="10" fontSize={["lg","xl","2xl"]} 
       letterSpacing=".09rem" p="3" >Spielplan</Heading> 
       </VisuallyHidden>     
            <StaticImage src="../images/theater/termine.jpeg" alt="Sponsoren" />
        </Box>
        
        </motion.div>
    )
}